export const AboutList = [
    {
        name: "Trouvez rapidement des professionnels qualifiés dans différents domaines.",
        id: "1"
    },
    {
        name: "Réservez des services à domicile en fonction de votre emploi du temps.",
        id: "2"
    },
    {
        name: "Bénéficiez d'une large sélection de professionnels fiables et évalués.",
        id: "3"
    },
    {
        name: "Profitez d'une expérience utilisateur fluide et intuitive",
        id: "4"
    }
]


 


