export const ServiceList = [
    {
        name: "Electricité",
        id: "1",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "2",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "3",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "4",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "5",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "6",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "7",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "8",
        image: "/pexels-gustavo-fring-4254171.jpg"
    },
    {
        name: "Electricité",
        id: "9",
        image: "/pexels-gustavo-fring-4254171.jpg"
    }
]