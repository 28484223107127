export const Contact = [
    {
        mail: "exemple@gmail.com",
        tel:  "+225010101010",
        lieu: "yopougon"
    },
    {
        mail: "exemple@gmail.com",
        tel:  "+225010101010",
        lieu: "yopougon"
    },
    {
        mail: "exemple@gmail.com",
        tel:  "+225010101010",
        lieu: "yopougon"
    }

]

export const Copy = [
    {
        copyright: "copyright Digital Renov"
    }
]