import Banner from "../Banner/Banner";
import NavBar from "../menu/NavBar"

function Emplacement() {
    return(
        <div>
            <NavBar/>
            <Banner/>
            <h1>Emplacement</h1>
        </div>
    )
}

export default Emplacement