export const Teams = [
    {
        image: "/pexels-andrea-piacquadio-3846033.jpg",
        name: "Konan kan",
        surnmane: "Julius seth kadmiel",
        metier: "charpentier",
        zones: "Yopougon",
        description: "En tant que charpentier passionné, je suis là pour concrétiser vos projets de construction et de rénovation en bois. Faites-moi confiance pour des résultats impeccables, des finitions soignées et une attention méticuleuse aux détails. Ensemble, créons un espace unique qui reflète votre style et répond à vos besoins."
    },
    {
        image: "/pexels-andrea-piacquadio-3846508.jpg",
        name: "Konan kan",
        surnmane: "Julius seth kadmiel",
        metier: "charpentier",
        zones: "Yopougon",
        description: "En tant que charpentier passionné, je suis là pour concrétiser vos projets de construction et de rénovation en bois. Faites-moi confiance pour des résultats impeccables, des finitions soignées et une attention méticuleuse aux détails. Ensemble, créons un espace unique qui reflète votre style et répond à vos besoins."
    },
]